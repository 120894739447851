import { RouteRecordRaw, RouterView } from 'vue-router';
import { h } from 'vue';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/activities-resume',
            name: 'activities-resume',
            component: () => import("./views/dashboard.vue")
        },
        {
            path: '/activity-data-recorder',
            name: 'activity-data-recorder',
            component: () => import("./views/activityDataRecorder.vue")
        },
        {
            path: '/data-inventory',
            name: 'data-inventory',
            component: () => import("./views/dataInventory.vue")
        },
        {
            path: '/target-setting',
            name: 'target-setting',
            component: () => import("./views/targetSetting.vue")
        },
        {
            path: '/premises',
            name: 'premises',
            component: () => import("./views/premises.vue")
        },
    ];
};