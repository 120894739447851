
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Chart from 'chart.js/auto'
  
@Options({
    components: {}
})
export default class LineChartVue extends Vue {
    
    @Prop() dataDictionary: { [key: string]: number; };
    @Prop({
        default: "kg CO2e"
    }) weightLabel: string;

    chartRef: any = null;
    chart: any = null;

    created(){
    }

    mounted() {
        this.init();
    }
  
    init() {
        const labels = Object.keys(this.dataDictionary); // Estrai le etichette
        const data = Object.values(this.dataDictionary); // Estrai i valori

        this.chartRef = this.$refs.lineChart as HTMLElement;
  
        // Creazione del grafico
        this.chart = new Chart(this.chartRef, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: this.weightLabel,
                        data: data,
                        borderColor: 'cyan', // Colore della linea
                        backgroundColor: 'rgba(0, 255, 255, 0.3)', // Colore di riempimento trasparente
                        fill: false, // Non riempire sotto la linea
                        pointRadius: 4,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 10, // Mostra massimo 10 tick sull'asse X
                        },
                    },
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
    }
}
