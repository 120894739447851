import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import { AdminClient, BaseAuthClient } from './Services';
import router from '@/router';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

class _LoginServices {
    
    LoginCallback(token: string, refreshToken: string){
        if(!token){
            LoginServices.logout();
            router.replace('/');
            return;
        }

        StorageServices.setNewTokens(token, refreshToken);

        BaseAuthClient.tokenUpgrade()
        .then( x => {
            var loggedUser = x;
            StorageServices.setLoginToken(x.token);
            StorageServices.setLoggedUser(loggedUser);
            store.state.loggedUser = loggedUser;
            store.state.selectedLanguage = loggedUser.preferredCulture;

            var fullCurrentUrl = window.location.href;
            var hashtagPos = fullCurrentUrl.indexOf("#");
            var currentUrl = fullCurrentUrl.substring(hashtagPos + 1, fullCurrentUrl.length);
            if(loggedUser.role == VM.Roles.Admin){
                if(currentUrl != "/login")
                    router.push(currentUrl);
                else
                    router.push("/garment");
            } else {
                if(currentUrl == "/login" || currentUrl == "/register" || router.currentRoute.value.name == "completeDomainLogin") {
                    if(loggedUser.hasProductLicense)
                        router.push("/dashboard");
                    else if(loggedUser.hasScope12License)
                        router.push("/activities-resume");
                    else {
                        LoginServices.logout();
                        router.replace('/');
                    }
                }
                else
                    router.push(currentUrl);
            }
        })
        .catch( err => {
            LoginServices.logout();
            router.replace('/');
        });

    };

    logout() {
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            this.resetLoggedUser();
            router.push("/");
            resolve();
        })
        return prom;
    }

    private resetLoggedUser() {
        var loggedUser = new OM.LoggedUserVM();
        loggedUser.preferredCulture = StorageServices.getLoggedUser().preferredCulture;
        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

}

export let LoginServices = new _LoginServices();