
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Chart from 'chart.js/auto';

@Options({
    components: {}
})
export default class MultipleLineChartVue extends Vue {
    @Prop() dataSets: Array<{ label: string; data: { [key: string]: number }; color: string }>; // Lista di dataset con label, dati e colore
    @Prop({
        default: "kg CO2e"
    }) weightLabel: string;

    chartRef: any = null;
    chart: any = null;

    mounted() {
        this.init();
    }

    init() {
        // Estrai tutte le chiavi (anni) dai dataset e uniscile per ottenere tutte le etichette uniche ordinate
        const labels = Array.from(
            new Set(this.dataSets.flatMap(ds => Object.keys(ds.data)))
        ).sort((a, b) => parseInt(a) - parseInt(b));

        // Configura i dataset, allineando i dati per anno
        const datasets = this.dataSets.map(ds => {
            const alignedData = labels.map(year => ds.data[year] ?? null); // Allinea i dati ai label
            return {
                label: ds.label,
                data: alignedData,
                borderColor: ds.color,
                backgroundColor: ds.color,
                fill: false, // Non riempire sotto la linea
                pointRadius: 4,
            };
        });

        this.chartRef = this.$refs.lineChart as HTMLCanvasElement;

        // Creazione del grafico
        this.chart = new Chart(this.chartRef, {
            type: 'line',
            data: {
                labels: labels,
                datasets: datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                spanGaps: true, // Abilita la connessione tra punti discontinui
                scales: {
                    x: {
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 10,
                        },
                        title: {
                            display: true,
                            text: "Anno",
                        },
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: this.weightLabel,
                        },
                    },
                },
            },
        });
    }
}
